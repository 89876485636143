// build dev
// const serverCore = 'https://core-dev.globalcare.vn/';

//build beta
// const serverCore = 'https://core-beta.globalcare.vn/';


// build live
const serverCore = 'https://core.globalcare.vn/';

export {
    serverCore
};